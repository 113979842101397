<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0156 1.37891C14.5898 1.51562 15 2.03516 15 2.60938C15 9.33594 9.53125 14.75 2.83203 14.75C2.25781 14.75 1.73828 14.3672 1.60156 13.793L1.02734 11.2227C0.890625 10.6211 1.19141 10.0195 1.73828 9.77344L4.52734 8.59766C5.01953 8.37891 5.62109 8.51562 5.97656 8.95312L6.87891 10.0469C8.30078 9.25391 9.50391 8.07812 10.2695 6.65625L9.17578 5.75391C8.73828 5.39844 8.60156 4.79688 8.82031 4.30469L10.0234 1.51562C10.2422 0.96875 10.8438 0.667969 11.4453 0.804688L14.0156 1.37891ZM2.88672 13.4375C8.82031 13.4102 13.6602 8.59766 13.6875 2.66406L11.1992 2.08984L10.0508 4.76953L11.9102 6.30078C10.625 9.00781 9.23047 10.4023 6.52344 11.6875L4.99219 9.82812L2.3125 10.9766L2.88672 13.4375Z"
      fill="currentColor"
    />
  </svg>
</template>
